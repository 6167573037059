import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { AppProps } from 'next/app'
import React, { ReactElement, useEffect } from 'react'
import getUserLocale from 'get-user-locale'
import { defaultLocale, dynamicActivate } from 'localization/index'
import { RootContainer } from '../containers/root-container'
import { useUserStore } from '../store/user'
import 'interface/src/styles.css'
import '../styles/styles.scss'
import { ToastContainer } from '../containers/toast-container'
import { useRefreshToken } from '../hooks/authentication/use-refresh-token'
import { useIsMobile } from '../hooks/misc/use-is-mobile'
import { UnsupportedDevice } from '../components/unsupported-device'

dynamicActivate(getUserLocale() || defaultLocale)

const MyApp = ({ Component, pageProps }: AppProps): ReactElement => {
  const { loadUserData } = useUserStore()
  const isMobile = useIsMobile()

  useEffect(() => {
    const run = async (): Promise<void> => {
      await loadUserData(pageProps.userToken)
    }
    run()
  }, [loadUserData, pageProps])

  useRefreshToken({ cookieName: pageProps.cookieName })

  return (
    <I18nProvider i18n={i18n}>
      <RootContainer>
        {isMobile ? (
          <UnsupportedDevice />
        ) : (
          <>
            <ToastContainer />
            <Component {...pageProps} />
          </>
        )}
      </RootContainer>
    </I18nProvider>
  )
}

export default MyApp
