import { path } from 'ramda'
import pkg from '../../package.json'
import { ensure } from '../lib/ts'
import { dev } from './dev'
import { production } from './production'
import { stage } from './stage'
import { ClientHeaders, Config, Configs } from './types'
import { getBrowserLocale } from '../lib/misc/get-browser-locale'

const configs: Configs = {
  dev,
  stage,
  production
}

type Env = 'production' | 'stage'

let env: Env = 'production'

if (global.window && global.window.location) {
  if (
    global.window.location.href.includes('master-stage.moises.ai') ||
    global.window.location.href.includes('dev.moises.ai')
  ) {
    env = 'stage'
  }
} else if (
  process.env.NEXT_PUBLIC_MOISES_ENV === 'development' ||
  process.env.FORCE_COOKIES
) {
  env = 'stage'
}

export const config: Config = ensure(path([env], configs))

export const getEnv = (): Env => env

export const getClientHeaders = (): ClientHeaders => {
  return {
    'apollographql-client-locale': getBrowserLocale(),
    'apollographql-client-name': 'ai.moises-studio-web',
    'apollographql-client-version': pkg.version
  }
}
