import React, { useCallback } from 'react'
import { Button, Icon } from 'interface'
import { Trans } from '@lingui/macro'

export const UnsupportedDevice: React.FC = () => {
  const onClickAction = useCallback(() => {
    const isStage = global.window.location.href.includes('stage')
    window.open(
      `https://studio${isStage ? '-stage' : ''}.moises.ai/library/`,
      '_parent'
    )
  }, [])

  return (
    <div
      id="unsuported-device"
      aria-label="unsupported-device"
      className="flex flex-col items-center gap-6 py-20 px-4 text-center "
    >
      <Icon name="mastering" width={80} height={80} strokeWidth={0.5} />
      <p className="text-xl font-semibold px-5">
        <Trans>Mastering is not supported on small screens</Trans>
      </p>
      <p className="text-base leading-5">
        <Trans>
          For full access and functionality, please use Mastering through a web
          browser on a larger screen device such as a computer or tablet.
        </Trans>
      </p>
      <div>
        <Button
          id="unsuported-device-button"
          stroke
          onClickAction={onClickAction}
        >
          <Icon name="arrow-left" />
          <Trans>Return to my Library</Trans>
        </Button>
      </div>
    </div>
  )
}
